let baseUrl
switch (process.env.VUE_APP_TITLE) {
    case 'test':
        // 本地
        // baseUrl = "https://lcw.scangoo.cn/";
        // 线上https://lcw.scangoo.cn
        baseUrl = "";
        break;
    case 'production':  
        baseUrl = "";
        break;
    default:
        baseUrl = "";
        break;
}
export default baseUrl;
