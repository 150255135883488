import * as Icons from '@element-plus/icons-vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 全局挂载api
import API from '@/api/api'
import vue3SeamlessScroll from "vue3-seamless-scroll"
// 引入rem
// import 'lib-flexible'
const app = createApp(App)
// 返回年月日
app.config.globalProperties.$formatTime = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
  //  ${hours}:${minutes}:${seconds}
};
// 返回年
app.config.globalProperties.$formatYear = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${year}`;
  //  ${hours}:${minutes}:${seconds}
};
for (let i in Icons) {
  app.component(i, Icons[i])
}
app.config.globalProperties.$API = API;

app.use(store).use(router).use(ElementPlus).use(vue3SeamlessScroll).mount('#app')