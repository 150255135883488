//api.js
import HTTP from "@/api/axios";
const API = {
  // 账密登录***
  login: function (data) {
    return HTTP.post("/api/user/login", data);
  },
  // 退出登录***
  logout: function (data) {
    return HTTP.get("/api/user/logout", data);
  },
  // 个人资料***
  getUser: function (data) {
    return HTTP.get("/api/user", data);
  },
  // 资料修改
  userProfile: function (data) {
    return HTTP.get("/api/user/profile", data);
  },
  // 广告列表***
  getAdv: function (data) {
    return HTTP.get("/api/adv", data);
  },
  // 手机号CODE列表***
  get_phone_country_code: function (data) {
    return HTTP.get("/api/dictionaries/get_phone_country_code", data);
  },
  // 分类列表***（部分调用)
  getCategory: function (data) {
    return HTTP.get("/api/dictionaries/category", data);
  },
  // 系统设置***
  getSystem: function (data) {
    return HTTP.get("/api/common/system", data);
  },
  // 短信发送
  smsSend: function (data) {
    return HTTP.post("/api/sms/send", data);
  },
  // 单页***
  getPage: function (id) {
    return HTTP.get(`/api/page/${id}`);
  },
  // 首页***
  getIndex: function (data) {
    return HTTP.get("/api/index",data);
  },
  // 公告列表***
  getArticle: function (data) {
    return HTTP.get("/api/article",data);
  },
  // 公告详情***
  getArticleDetails: function (id) {
    return HTTP.get(`/api/article/${id}`);
  },
  // 专场详情***
  getTopic: function (id) {
    return HTTP.get(`/api/topic/${id}`);
  },
  // 项目经理下拉列表***
  managerSelect: function (data) {
    return HTTP.get("/api/manager/select",data);
  },
  // 职位选项统计***（部分调用）
  positionTab: function (data) {
    return HTTP.post("/api/position/tab",data);
  },
  // 职位列表***(部分调用)
  position: function (data) {
    return HTTP.post("/api/position",data);
  },
  // 职位详情***(部分调用)
  positionDetails: function (id) {
    return HTTP.get(`/api/position/${id}`);
  },
  // 职位接单***(部分调用)
  positionTake: function (id) {
    return HTTP.get(`/api/position/take/${id}`);
  },
  // 职位取消接单***(部分调用)
  positionCancel: function (id) {
    return HTTP.get(`/api/position/cancel/${id}`);
  },
  // 职位查重***(部分调用)
  positionCheck: function (data) {
    return HTTP.get("/api/position/check",data);
  },
  // 职位推荐***（部分调用）
  positionRecommend: function (data) {
    return HTTP.post("/api/position/recommend",data);
  },
  // 简历列表***（部分调用）
  resume: function (data) {
    return HTTP.get("/api/resume",data);
  },
  // 简历推荐简历列表***（部分调用）
  resumeReport: function (id) {
    return HTTP.get(`/api/resume/report/${id}`);
  },
  // 简历添加推荐理由***（部分调用）
  resumePost: function (id,data) {
    return HTTP.post(`/api/resume/report/${id}`,data);
  },
  // 简历编辑推荐理由***（部分调用）
  resumePut: function (id,data) {
    return HTTP.put(`/api/resume/report/${id}`,data);
  },
  // 简历删除推荐理由***（部分调用）
  resumeDeleLiy: function (id) {
    return HTTP.delete(`/api/resume/report/${id}`);
  },
  // 地区列表(市级)***（部分调用）
  areaCity: function (data) {
    return HTTP.get("/api/area/city",data);
  },
  // 地区列表(省级)***（部分调用）
  areaProvince: function (data) {
    return HTTP.get("/api/area/province",data);
  },
  // 地区列表(下级)***（部分调用）
  areaJunior: function (id) {
    return HTTP.get(`/api/area/${id}`);
  },
  // 简历删除***
  resumeDele: function (id) {
    return HTTP.delete(`/api/resume/${id}`);
  },
  // 简历详情***
  resumeDetail: function (id) {
    return HTTP.get(`/api/resume/${id}`);
  },
  // 简历编辑基本信息***
  resumeBasic: function (id,data) {
    return HTTP.post(`/api/resume/basic/${id}`,data);
  },
  // 简历编辑求职意向***
  resumeIntention: function (id,data) {
    return HTTP.post(`/api/resume/intention/${id}`,data);
  },
  // 简历工作经历列表***
  resumeWork: function (id) {
    return HTTP.get(`/api/resume/work/${id}`);
  },
  // 简历添加工作经历***
  addResumeWork: function (id,data) {
    return HTTP.post(`/api/resume/work/${id}`,data);
  },
  // 简历编辑工作经历***
  putResumeWork: function (id,data) {
    return HTTP.put(`/api/resume/work/${id}`,data);
  },
  // 简历删除工作经历***
  delResumeWork: function (id) {
    return HTTP.delete(`/api/resume/work/${id}`);
  },
  // 简历添加项目经验***
  addResumeProject: function (id,data) {
    return HTTP.post(`/api/resume/project/${id}`,data);
  },
  // 简历编辑项目经验***
  putResumeProject: function (id,data) {
    return HTTP.put(`/api/resume/project/${id}`,data);
  },
  // 简历删除项目经验***
  delResumeProject: function (id,) {
    return HTTP.delete(`/api/resume/project/${id}`);
  },
  // 简历添加教育经历***
  ResumeEducation: function (id,data) {
    return HTTP.post(`/api/resume/education/${id}`,data);
  },
  // 简历编辑教育经历***
  putResumeEeducation: function (id,data) {
    return HTTP.put(`/api/resume/education/${id}`,data);
  },
  // 简历删除教育经历***
  delResumeEeducation: function (id) {
    return HTTP.delete(`/api/resume/education/${id}`);
  },
  // 简历更新***(部分调用)
  updateresumePut: function (id,data) {
    return HTTP.post(`/api/resume/update/${id}`,data);
  },
  // 简历创建***(部分调用)
  upresumePost: function (data) {
    return HTTP.post("/api/resume",data);
  },
  // 员工列表***(代理已调用)
  getStaff: function (data) {
    return HTTP.get("/api/staff",data);
  },
  // 员工详情***(代理已调用)
  getStaffDetail: function (id) {
    return HTTP.get(`/api/staff/${id}`);
  },
  // 员工添加***(代理已调用)
  postStaff: function (data) {
    return HTTP.post("/api/staff",data);
  },
  // 员工编辑
  putStaff: function (id,data) {
    return HTTP.put(`/api/staff/${id}`,data);
  },
  // 员工删除***(代理已调用)
  delStaff: function (id) {
    return HTTP.delete(`/api/staff/${id}`);
  },
  // 员工禁用/启用***(代理已调用)
  StaffStatus: function (id,type) {
    return HTTP.get(`/api/staff/status/${id}/${type}`);
  },
  // 经理员工代理下拉列表***(代理已调用)
  userSelect: function (data) {
    return HTTP.get("/api/user/select",data);
  },
  // 员工 - 更换上级经理***
  staffChange: function (staff_id,manager_id) {
    return HTTP.get(`/api/staff/change/${staff_id}/${manager_id}`);
  },
  // 员工 - 转移简历/订单***(新增接口)
  transferStaff: function (staff_id,user_id) {
    return HTTP.get(`/api/staff/transfer/${staff_id}/${user_id}`);
  },
  // 业绩选项统计***(代理已调用)
  chargesTab: function (data) {
    return HTTP.get("/api/charges/tab",data);
  },
  // 业绩列表***(代理已调用)
  charges: function (data) {
    return HTTP.get("/api/charges",data);
  },
  // 提现申请***(代理已调用)
  userWithdraw: function (data) {
    return HTTP.post("/api/user/withdraw",data);
  },
  // 提现记录列表***(代理已调用)
  withdrawget: function (data) {
    return HTTP.get("/api/withdraw",data);
  },
  // 提现记录详情{接口待完善}
  withdrawPost: function (id) {
    return HTTP.post(`/api/withdraw/${id}`);
  },
  // 订单- 选项统计***(代理调用)
  orderTab: function (data) {
    return HTTP.get("/api/order/tab",data);
  },
  // 订单- 列表***(代理调用)
  order: function (data) {
    return HTTP.get("/api/order",data);
  },
  // 公司-下拉列表***(代理调用)
  projectSelect: function (data) {
    return HTTP.get("/api/project/select",data);
  },
  // 职位 - 下拉列表***(代理调用)
  positionSelect: function (data) {
    return HTTP.get("/api/position/select",data);
  },
  // 订单- 添加备注***(代理调用)
  orderrEmark: function (id,data) {
    return HTTP.post(`/api/order/remark/${id}`,data);
  },
  // 订单- 终止订单***(代理调用)
  orderrEnd: function (id) {
    return HTTP.get(`/api/order/end/${id}`);
  },
  // 找回密码***
  forgetpwd: function (data) {
    return HTTP.post("/api/user/forgetpwd",data);
  },
  // 修改密码***
  resetpwd: function (data) {
    return HTTP.post("/api/user/resetpwd",data);
  },
  // 推荐职位列表***
  positionList: function (data) {
    return HTTP.get("/api/position/list",data);
  },
  // 20241023新增接口
  // 简历 - 选项统计***
  resumeTabs: function (data) {
    return HTTP.get("/api/resume/tab",data);
  },
  // 20241114新增接口
  // 员工 - 显示佣金***
  show_charges: function (id,type) {
    return HTTP.get(`/api/staff/show_charges/${id}/${type}`);
  },
  // 员工 - 显示佣金***
  orderRestart: function (id) {
    return HTTP.get(`/api/order/restart/${id}`);
  },
}

export default API